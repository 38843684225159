import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Container, } from 'components/library';
import { WEBSITE_BASE_URL_JAPAN } from 'variables';

const RedirectingToJapan = () => {

  useEffect(() => {
    window.location.href = WEBSITE_BASE_URL_JAPAN
  }, [])

  return (
    <main className="flex justify-center bg-404-mobile bg-cover lg:bg-404-desktop">
      <Container className='flex flex-col gap-10 py-32 items-center'>
        <div className='w-16 h-16 rounded-full bg-theme-blue animate-bounce flex justify-center items-center'>
          <span className='w-10 h-10 bg-theme-blue-hover rounded-full animate-ping'></span>
          <span className='sr-only absolute'>loading...</span>
        </div>

        <h1 className='text-center gap-6 text-4xl md:text-6xl max-w-4xl'>
          Redirecting to the Japanese Website
        </h1>
      </Container>
    </main>
  );
};

export default RedirectingToJapan;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 